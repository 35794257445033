@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Bitter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Finlandica:ital@0;1&display=swap");

.input-url {
  @apply border border-gray-400 rounded-md p-1;
}

.articleTitle {
  font-family: "Bitter";
  @apply text-3xl font-bold;
}

.articleBody {
  font-family: "Bitter";
  @apply whitespace-pre-wrap;
}

.main {
  /* @apply max-w-4xl mx-auto p-2; */
  /* @apply bg-slate-50 ring-1 ring-slate-400 rounded shadow-lg shadow-slate-700; */
}

.sidebar {
  @apply fixed top-0 left-0 z-40 w-64 h-screen;
}

.content {
}

.button {
  @apply text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800;
}

.inline-button {
  @apply bg-blue-500 text-white font-bold py-1 px-1 rounded;
}

.row {
  @apply bg-cyan-700;
  @apply p-1;
  @apply border-spacing-1 border-cyan-950 border rounded-md p-1;
}

.row-label {
  @apply w-44;
}
.row-value {
  @apply bg-cyan-900;
  @apply border border-cyan-950 rounded-md p-1;
}

.type-fields {
  @apply ml-4;
}
